
import { Component, OnInit } from '@angular/core';
import { QuickSightService } from 'app/_services/quicksight/quicksight.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-quicksight-detenciones',
  templateUrl: './quicksight.component.html',
  styleUrls: ['./quicksight.component.css']
})
export class QuickSightDetencionesComponent implements OnInit {
  embedUrl: SafeResourceUrl | null = null; // Usamos SafeResourceUrl en lugar de string

  constructor(private quickSightService: QuickSightService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.quickSightService.getEmbedUrlDetenciones().subscribe(
      (url) => {
        this.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url); // Sanitizar la URL
      },
      (error) => {
        console.error("Error obteniendo la URL de QuickSight:", error);
      }
    );
  }
}

