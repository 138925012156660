import { UsersService } from './users/users.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Subject, Observable, pipe, of, Subscription, throwError } from 'rxjs';
import { map, retryWhen, delay, switchMap, retry, catchError, timeout } from 'rxjs/operators';

import { WPSID } from 'app/_class/wpsid';
import { Cliente } from 'app/_class/cliente';
import { Point } from 'app/_class/point';
import { Permiso } from 'app/_interfaces/permiso';
import { JsonHc } from 'app/_interfaces/json-hc';
import { Grupo } from 'app/_class/grupo';
import { ResponsePermisoHTTP } from 'app/_interfaces/response-permiso-http';
import { ResponseRecoverEmail } from 'app/_interfaces/response-recover-email';
import { ReverseGeocodingResponseIQ } from 'app/_interfaces/reverse-geocoding-response-iq';

import { Actividad } from 'app/_class/indicadores/actividad';
import { Alerta } from 'app/_class/indicadores/alerta';
import { FlotaActiva } from 'app/_class/indicadores/flota-activa';
import { Rendimiento } from 'app/_class/indicadores/rendimiento';
import { GetClases } from 'app/_class/get-clases';
import { StorageService } from './storage/storage.service';
import { SessionCheckService } from './sesionCheck/sessionCheck.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnDestroy {
  /** emite el valor de PHPSESSID cuando se inicia la sesion en PHP */
  $phpsessid = new Subject<string>();
  $sesion = new Subject<boolean>();
  $idCliente = new Subject<number>();
  $cargarClientes = new Subject<boolean>();
  idCliente = -1;
  $nombreCliente = new Subject<string>();
  nombreCliente = "foo"
  hasSession = false;
  wpsid: WPSID = new WPSID('');
  isInLogin = false;
  cookies = {
    config: {
      domain: 'waypoint.cl',
      path: '/'
    },
    newWebapp: { name: 'newWebapp', value: { active: 'true', inactive: 'false' } },
    idUsr: { name: '0x100', value: '' }
  };
  idUsr: number;
  permisos: Permiso[];
  $permiso = new Subject<Permiso[]>();
  usrName = '';
  pass = '';
  refUserName = 'usrName';
  userType = 0;
  // clientes para el usuario actual
  clientes: Cliente[];
  static readonly LIST_USERS: number[] = [14156,14331,11365,14235,14238,14236,14237,22883,14518,26775,14320,14303,18103,14322,14324,14321,14328,14315,14240,14242,14316,14323,14420,14419,14486,14485,14495,14409,26773,6332,26774,26707,18254,17987,20953,20978,9099,21757,9840,11015,26182,26188,26183,26061,26062,22984,26175,26185,26187,26085,22985,22791,22933,22817,23054,23055,22882,22991,22995,23066,23559,22645,23606,26799,23314,24058,26738,26735,24424,24488,26712,26135,17752,26134,26181,14369,26734,26171,4132,4155,4721,4777,5253,5787,6487,6591,23909,23607,23423,26796,7272,8872,8900,8904,10570,10876,10893,11800,11801,14161,14170,419,26179,26886,26201,26186,5937,26901,23086,26771,26701,26885,26184,3349,26785,26180,24949,10708,26316,26893,26178,22773];

  pipelingIndicadores = pipe(
    switchMap(data => of(data)),
    map((data: any) => {
      data = new Rendimiento(data);
      if (!data.isComplete()) {
        console.log('INCOMPLETE');
        return;
      }
      return data;
    }),
  );

  dominio = 'localhost';

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly usersService: UsersService,
    private readonly storage: StorageService,
    private readonly sessionCheckService: SessionCheckService,
    private readonly cs: CookieService) {
    if (window.location.hostname.endsWith('waypoint.cl')) {
      this.dominio = 'waypoint.cl';
    }
    if (window.location.hostname.endsWith('tranciti.com')) {
      this.dominio = 'tranciti.com';
    }
    /* Escucha en caso de que se ciere la sesion */
    this.$sesion.pipe().subscribe(hasSesion => {
      this.hasSession = hasSesion;
      if (!hasSesion && !this.isInLogin) {
        this.cerrarSesion(true);
      }
    });
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  subs : Subscription;

  async activarService() {
    // carga los clientes para el select
    await this.cargarClientes();
    //  actualiza los permisos cuando cambia  el id de Cliente
    this.subs = this.$idCliente.subscribe((idCliente: number) => {
    //  envia datos de user atributes para Hotjar al iniciar sesion y cambiar cliente (user,clientId)
      this.sendToHotjar(idCliente);

      this.idCliente = Number(idCliente);
      this.getPermisos(this.idCliente).subscribe((responsePermiso: ResponsePermisoHTTP) => {
        this.permisos = responsePermiso.permisos;
        this.$permiso.next(this.permisos);
      });
    });

    return true;
  }


  async cargarClientes() {

    this.getClientes().subscribe(async response => {
      const content = JSON.stringify(response);

      const parsedData = JSON.parse(content);
      const data = parsedData ? parsedData.data : [];
      if (data.length === 1 && data.error === 'no sesion') {
        await this.cerrarSesion(true);
      }


      if (data.length > 0 && data[0].error === undefined) {

        const arrClient: Cliente[] = data;
        this.clientes = [];
        for (const tmp of arrClient) {
          this.clientes.push(tmp);
        }
        const client = this.storage.get('dash', 'client');
        const clientSelectedId = client ? client : this.clientes[0].id;
        const clientSelectedName = this.clientes.find(e => e.id === clientSelectedId).name;

        this.idCliente = clientSelectedId;
        this.setCookie('idcliente', clientSelectedId);
        this.setCookie('nombreCliente', clientSelectedName)
        this.$idCliente.next(clientSelectedId);
        this.$cargarClientes.next(true);

      } else {
        console.error(content);
        if (content === '[{"error":"no sesion"}]') {
          await this.cerrarSesion(true);
        }
      }
      return true;
    },
      error => {
        console.log(error.error);
      });

    return true;
  }
  // al cerrar sesion con el login nuevo deja esta cookies que despues se usan para redirigirte
  redirectLogout(){
    // Al hacer logout, se checkea si es parte del login nuevo
    const logout = localStorage.getItem("logout");
    const goToLogin = this.cs.get('newLogin');
    if(logout !== undefined && logout === 'true' && goToLogin !== undefined && goToLogin !== null && goToLogin !== ''){
      localStorage.removeItem("logout")
      console.log("newLogin close session by logout");
      const to = `${environment.appLogin}/logout`
      window.location.href = to;
      return;
    }
  }

  isSessionValid(origen){
    return this.sessionCheckService.isSessionValid(origen)
  }
  /**
   *  Recovery the value of  cookie wpsid and  set the value  to  {thishasSession}
   *
   * Return true if the cookie is not empty or  else false
   *
   *
   * @returns boolean
   */
  recoverSession(): boolean {
    console.log("recovering session");
    if (this.wpsid === undefined) {
      this.wpsid = new WPSID('');
    }
    const recoveryValue = this.cs.get('wpsid')
    if (recoveryValue !== undefined) {
      this.wpsid.value = recoveryValue;
    }
    if (this.usrName === undefined) {
      this.usrName = this.getCookie(this.refUserName);
    }

    const idTemp = parseInt(this.getCookie(this.cookies.idUsr.name), 10);
    if (!isNaN(idTemp)) {
      this.idUsr = idTemp;
    }

    this.hasSession = this.wpsid.value && this.wpsid.value.length > 5;
    console.log("thish", this.hasSession);
    return this.hasSession;
  }

  obtenerCognito(cognito): boolean {
    let cgn = false;
    const paramName = 'AuthenticationResult';
    console.log(cognito);

    if (typeof cognito !== 'number') {
      try { // obtener cognito
        if (typeof cognito === 'object' && typeof cognito[paramName].IdToken === 'string') {
          const token = cognito[paramName].AccessToken;
          //Se almacena el refreshToken (Motivo de esto es para poder usarlo en lastmile para renovacion de token para servicios utilizados alla)
          const refreshToken = cognito[paramName].RefreshToken;
          console.log(token);
          localStorage.setItem("crossToken", token)
          this.setCookie('at', token);
          this.setCookie('rt', refreshToken);
          cgn = true;
        }
      } catch (e) {
        console.log(e);
      }
    }
    return cgn;
  }

  isSessionPhpStarted(){
    const phpsessid: string = this.cs.get('PHPSESSID')
    return !!phpsessid;
  }

  // inicia sesion cuando utiliza algún php
  validatePHPSession(){
    const at: string = this.cs.get('at');
    const infoDec = this.getInfoToken(at);
    let usr = infoDec["username"]

    this.loginPHP(usr, null, at).then(
      httpResponse => {
        const data = httpResponse.body.toString();
        const strHeader = 'PHPSESSID';

        const PHPSESSID = httpResponse.headers.get(strHeader);
        this.setCookie('PHPSESSID', PHPSESSID);
        this.$phpsessid.next(PHPSESSID);

        const index = data.indexOf(';');
        if (!(data.indexOf('Error de acceso') < 0 && index > 0)) {
          console.log('ERROR LOGIN PHP  ' + data);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getInfoToken(token: string) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  }

  // crea la Sesion
  logIn(usr: string, pas: string) {
    this.isInLogin = true;
    this.usrName = usr;
    this.pass = encodeURIComponent(pas);
    this.loginTomcat().subscribe(
      response => {
        const cgn = this.obtenerCognito(response.body);
        if (cgn || response.body === 5) {
          const userInfo = response.body["AuthenticationResult"].IdToken;

          const infoDec = this.getInfoToken(userInfo);
          this.idUsr = infoDec["custom:id"]

          this.hasSession = true;
          const wp = response.headers.get('wpsid');
          this.wpsid = new WPSID(wp);
          this.saveUser(wp, this.idUsr);
          localStorage.setItem('wpsid', this.wpsid.value);
          localStorage.setItem('id', this.idUsr + '');
          localStorage.setItem('usrName', this.usrName);
          if(LoginService.LIST_USERS.includes(this.idUsr)){
            this.setCookie('newLogin', true);
          }
          this.setCookie('wpsid', this.wpsid.value);
          this.setCookie('usrName', usr);
          this.setCookie(this.cookies.newWebapp.name, this.cookies.newWebapp.value.active);
          this.setCookie(this.cookies.idUsr.name, this.idUsr);
          this.setCookie('JSESSIONID', this.wpsid.value);
          this.deleteRemoteCookie();

          // async validation
          this.validatePHPSession();

          this.$sesion.next(true);
        } else {
          console.error('No se pudo validar el id de usuario: ' + this.usrName);
          this.$sesion.next(false);
        }

        this.isInLogin = false;
      },
      err => {
        console.log('HTTP ERROR 1 ' + err);
        this.$sesion.next(false);
        this.isInLogin = false;
      }
    );

    return this.$sesion.asObservable();
  }

  async saveUser(wpsid: string, idp: number) {
    const data = await this.usersService.getData(wpsid, idp);
    console.log("DATA", data);
    if (data && data.usuario) {
      const { nombre, apellido, id } = data.usuario;
      let { tipo } = data.usuario;
      tipo = tipo && tipo !== undefined ? tipo : 0;
      localStorage.setItem('name', `${nombre} ${apellido}`);
      localStorage.setItem('type', tipo);
      localStorage.setItem('id', id + '');
    }
  }

  loginTomcat() {
    const dom = this.dominio;
    const urlp1 = `${environment.endPoint}/WaypointSessions/OpenSessionAsync?usuario=`;
    const url = `${urlp1}${this.usrName}&pass=${this.pass}&dom=${dom}`;
    console.log(`Url de sesion: ${url}`);

    let data = new HttpParams();
    data = data.set('usuario', this.usrName);

    return this.http.post(url, data, { observe: 'response' });
  }

  loginPHP(usr: string, pas: string, token: string) {
    console.log("en login php");
    const url = environment.endPoint + '/clientes/login_async.php';
    const bean = Math.round(Math.random() * (99999999 - 10000) + 10000);
    let data = new HttpParams();
    data = data.set('campo_usuario', bean.toString());
    data = data.set('return_id', 'true');
    if(token){
      data = data.set('token', token);
    }else{
      data = data.set('pass', encodeURIComponent(pas));
    }
    data = data.set('user_' + bean, usr);
    data = data.set('version', 'v2');
    data = data.set('login_url', '/clientes/');
    console.log("la url", url);
    return this.http.post(url, data, { responseType: 'text', observe: 'response' }).pipe(timeout(10000)).toPromise();

  }
  public setCookie(name, value) {
    const d = new Date();
    d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
    localStorage.setItem(name, value);

    if (this.dominio === 'localhost') {
      this.cs.set(name, value, d, '/', this.dominio, false, 'Strict');
    } else {
      this.cs.set(name, value, d, '/', this.dominio, true, 'Strict');
    }

  }

  private deleteCookie(name: string) {
    this.cs.delete(name);
  }

  /**
   * Borra las cookies  con path webapp.waypoint.cl
   *
   */
  private deleteRemoteCookie(): void {
    const url = environment.endPoint + '/clientes/deleteCookie.php';
    this.http.get(url).subscribe(data => data);
  }

  private getCookie(cname) {
    return (document.cookie.match(`(^|;) *${cname}=([^;]*)`) || '')[2];
  }

  // obtiene  la lista de clientes por  usuario
  getClientes() {
    const url = `${environment.coreEndpoint}/user/${this.idUsr}/clients`;
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8')
    };
    return this.http.get(url, httpOptions);
  }

  forgotPasswordAutomatic(user) {
    const formData: FormData = new FormData();
    formData.append('info', user);

    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    const url = environment.endPoint +'/clientes/forgotpass_automatic.php';
    this.http.post(
      url,
      {info: user},
      httpOptions
    ).subscribe((data) => {
      console.log("peticion enviada");
    });
  }
  stopSessionCheck(){
    this.sessionCheckService.detenerMonitoreo("cerrarSesion")
  }
  // Cierra la Sesion
  async cerrarSesion(redirect: boolean) {
    console.warn('__CERRANDO_SESSION__');
    const controllerWP = new AbortController();
    const controllerPHP = new AbortController();

    const signalWP = controllerWP.signal;
    setTimeout(() => {
      controllerWP.abort();
    },5000)
    const signalPHP = controllerPHP.signal;
    setTimeout(() => {
      controllerPHP.abort();
    },5000)
    // Sesión de WaypointSessions
    const logoutWpSessions = `${environment.endPoint}/WaypointSessions/CloseSession?to=`; //el "to" vacío hace que no redirija
    await fetch(logoutWpSessions, {
      method: "GET",
      credentials: "include" as RequestCredentials,
      redirect: "manual" as RequestRedirect, //si cambia el comportamiento del "to" igualmente no queremos redirigir
      signal: signalWP
    }).then(data => {
      console.log( data.text() );
      console.log('Session WaypointSession: closed')
    }).catch(() => console.error('Session WaypointSession: process error'));
    
    // Sesión legacy de PHP
    const to = `?to=${environment.endPoint}/v2/`;
    const red = '&red=false';
    const url = `${environment.endPoint}/clientes/logoff.php${to}${red}`;
    if (this.getCookie('PHPSESSID')){
      await fetch(url, {
        method: "GET",
        credentials: "include" as RequestCredentials,
        redirect: "manual" as RequestRedirect,
        signal: signalPHP
      }).then(data => {
        console.log( data.text() ); 
        console.log('Session PHP: closed')
      }).catch(() => console.error('Session PHP: process error'));
    }

    // Borrado de cookies y redirect
    this.stopSessionCheck()
    this.borrarDatosDeSesion()
    this.usrName = undefined;
    if(LoginService.LIST_USERS.includes(this.idUsr)){
      this.setCookie('newLogin', true);
      localStorage.setItem("logout",'true');
      this.redirectLogout();
    } else{
      this.router.navigate(['login']);
    }
  }

  borrarDatosDeSesion(){
    // borra las cookies
    this.cs.deleteAll('/', this.dominio);
    // borra los datos de local storage
    Object.keys(localStorage).forEach(key => {
        if (!key.startsWith('comp') && !key.startsWith('allFilters')) {
            localStorage.removeItem(key);
        }
    })
  }

  // Obtiene la lista  de vehiculos por cliente

  getVehiculos(idCliente: number): Observable<JsonHc> {
    return this.http.get<JsonHc>(`${environment.endPoint}/Req/GetJsonHC?idcliente=${this.idCliente}&${this.wpsid.getUrlEncode()}`);
  }

  getGrupos(idCliente: number): Observable<Grupo[]> {
    const url = `${environment.endPoint}/Req/GetGrupo?id_cliente=${idCliente}&${this.wpsid.getUrlEncode()}`;

    return this.http.get<Grupo[]>(url);

  }

  // obtiene esquema de permnisos
  getPermisos(idCliente: number) {/// TO DO
    const url = environment.endpointPermisos;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const request: any = {};
    request.wpsid = this.wpsid.value;
    request.idCliente = idCliente.toString();
    request.usuario = this.usrName.toString();
    return this.http.post<ResponsePermisoHTTP>(url, request, httpOptions);
  }

  /**
   * Obtiene la direccion dada  unas coordenadas lat,lng
   *
   * @param Point point
   * @returns Observable<ReverseGeocodingResponseIQ>
   */
  getDireccion(point: Point): Observable<ReverseGeocodingResponseIQ> {
    let hostname: string
    try{
      hostname = window.location.href
      hostname = encodeURIComponent(hostname)
    } catch(e) {
      hostname = 'sin_especificar_webapp'
    }

    const url = `${environment.locationIQ.endPointReverseGeocoding}?info=detailed&lat=${point.lat}&lon=${point.lng}&project=${hostname}`;
    return this.http.get<ReverseGeocodingResponseIQ>(url);
  }

  recuperarContrasenia(email: string) {
    const url = `${environment.endPoint}/clientes/forgotpass_webapp.php`;
    let data = new HttpParams();
    data = data.set('info', email);
    return this.http.post<ResponseRecoverEmail>(url, data);
  }


  // Operaciones para el Api EndPoint


  getIndicadorFlota(groups: number[], fleets: number[], vehicles: number[]) {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.apiEndPoint}/flota?`;
    const params = `idCliente=${this.idCliente}&grupos=${groups.join(',')}&flotas=${fleets.join(',')}&vehiculos=${vehicles.join(',')}&iduser=${this.getUserId()}`;
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8')
    };
    return this.http.get<FlotaActiva>(`${url}${params}`, httpOptions).pipe(
      map((data: any) => {
        data = new Rendimiento(data);
        if (!data.isComplete()) {
          console.log("reintentar flota");
          return;
        }
        return data;
      }),
      retryWhen(errors => errors.pipe(delay(10000)))

    );
  }

  getUserId() {
    const id = localStorage.getItem('id');
    return id && id !== undefined ? id : 0;
  }

  getIndicadorAlerta(groups: number[], fleets: number[], vehicles: number[]) {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.apiEndPoint}/alertas?`;
    const params = `idCliente=${this.idCliente}&grupos=${groups.join(',')}&flotas=${fleets.join(',')}&vehiculos=${vehicles.join(',')}&iduser=${this.getUserId()}`;

    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8')
    };
    return this.http.get<Alerta>(`${url}${params}`, httpOptions).pipe(
      this.pipelingIndicadores,
      retry(3),
      catchError(err => this.errorHandler(err))
      );
  }

  getIndicadorActividad(groups: number[], fleets: number[], vehicles: number[]) {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.apiEndPoint}/actividad?`;
    const params = `idCliente=${this.idCliente}&grupos=${groups.join(',')}&flotas=${fleets.join(',')}&vehiculos=${vehicles.join(',')}&iduser=${this.getUserId()}`;

    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8')
    };
    return this.http.get<Actividad>(`${url}${params}`, httpOptions).pipe(
      this.pipelingIndicadores,
      retry(3),
      catchError(err => this.errorHandler(err))
      );
  }


  getClasesVeviculo(idCliente: number = -1): Observable<GetClases> {
    let aux = "";
    if (idCliente > 0) {
      aux = `&idcliente=${idCliente}&opt=porCliente`;
    }
    const url = `${environment.endPoint}/WebappAdmin/GetClases?${this.wpsid.getUrlEncode()}${aux}`;
    

    return this.http.get<GetClases>(url).pipe(
      retry(3),
      catchError(err => this.errorHandler(err))
    );
  }

  sendToHotjar(idCliente) {
    // hotjar integración
    const hj = (window as any).hj;
    hj('identify', this.usrName, {
      // Add your own custom attributes here.
      'report_id': null,
      'report_name': null,
      'client_id': idCliente,

    });

  }

  errorHandler = (error: HttpErrorResponse) => {
    let errorMsg = '';
    if (error.error instanceof ErrorEvent) {
        console.log('getpoints: error del lado del cliente');
        errorMsg = `Error: ${error.error.message}`;
    } else {
        errorMsg = `Código de error: ${error.status}, Mensaje: ${error.message}`
    }
    console.error(errorMsg);
    return throwError(errorMsg)
  }

}
