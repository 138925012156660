import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuickSightService {
  private apiUrl = 'https://0br7kai81h.execute-api.us-east-1.amazonaws.com/v1/usabilidad'; 
  private apiUrlDetenciones = 'https://0br7kai81h.execute-api.us-east-1.amazonaws.com/v1/detenciones'; 
  
  constructor(private http: HttpClient) {}
  getEmbedUrl(): Observable<string> {

    return this.http.get<any>(this.apiUrl).pipe(
      map(response => {
        if (response.body) {
          const parsedBody = JSON.parse(response.body);
          return parsedBody.embedUrl;
        }
        throw new Error("No se pudo obtener la URL del tablero");
      })
    );
  };
  getEmbedUrlDetenciones(): Observable<string> {

    return this.http.get<any>(this.apiUrlDetenciones).pipe(
      map(response => {
        if (response.body) {
          const parsedBody = JSON.parse(response.body);
          return parsedBody.embedUrl;
        }
        throw new Error("No se pudo obtener la URL del tablero");
      })
    );
  }
}
