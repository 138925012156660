import { Component } from '@angular/core';

@Component({
  selector: 'app-new-modulo-bi-detencion',
  templateUrl: './new-modulo-bi-detencion.component.html', // Usa el HTML externo
  styleUrls: ['./new-modulo-bi-detencion.component.css']  // Usa el CSS externo
})
export class NewModuloBiDetencionComponent {
   // JSON de respuesta
}
