<header>
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap" rel="stylesheet">
  <!-- Barra superior -->
  <div class="navbar top-bar">
    <div class="left-section">
      <a href="/#/dashboard"  class="logo">
        <img src="./logo.png">
      </a>
    </div>
  </div>
  
  <!-- Barra inferior con pestañas -->
  <div class="navbar bottom-bar">
    <div class="menu">Analítica Tranciti</div>
    <nav class="tabs">
      <a href="#" class="tab active">Detenciones</a>
    </nav>
  </div>
</header>
<body>
  <div class="new-modulo-container">
    <h2>Tablero de Analítica Tranciti</h2>
    <app-quicksight-detenciones></app-quicksight-detenciones> <!-- Llamando al componente QuickSight -->
  </div>
</body>